import { useRouteLoaderData } from "@remix-run/react";
import { LoaderType } from "app/routes/__vendor-portal";

export function useCurrentUser() {
  const { session } = useRouteLoaderData(
    "routes/__vendor-portal",
  ) as LoaderType;

  return session;
}
